import React from 'react';

import SEO from "../../components/seo";
import Layout from "../../components/layout";
import ResearchSection from "../../components/ResearchSection";
import MobileResearch from '../../containers/mobile/Research';

const ResearchPage = (props: any) => {
  return (
    <Layout>
      <SEO title="Research" />
      <ResearchSection />
      <MobileResearch />
    </Layout>
  );
};


export default ResearchPage;
