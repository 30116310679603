import { graphql, Link, StaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import IResearchSection from '../../interfaces/ResearchSection';
import Colors from '../../colors';

const query = graphql`
  query MobileResearchSection {
    allSectionsYaml {
      nodes {
        name
        products {
  				name
        }
      }
    }
  }
`;

const Arrow = () => {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none">
      <path d="M1 19L10 10L0.999999 1" stroke="white" stroke-width="1.5"/>
    </svg>
  )
};

const StyledLayout = styled.div`
  display: none;
  flex-direction: row;
  overflow: hidden;

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    margin: 0;
    cursor: pointer;

    a {
      padding: 14px 0;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: ${Colors.primary};
      text-decoration: none;
    }
  }

  li:not(:last-child) {
    border-bottom: 1px solid #228694;
  }

  .section {
    transition: all .3s;
    transform: translateX(0%);
    padding: 64px 10%;
  }

  .section.step-2 {
    transform: translateX(-100%);
  }

  .section-selection {
    flex: 1 0 100%;
  }

  .product-selection {
    flex: 1 0 100%;
  }

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

const Research = () => {
  const [isStep2, setIsStep2] = useState(false);

  return (
    <StaticQuery
      query={query}
      render={data => {
        const sections: IResearchSection[] = data.allSectionsYaml.nodes;
        const [selectedSection, setSelectedSection] = useState(sections[0]);

        return (
          <StyledLayout>
            <div className={
                classNames(
                  'section',
                  'section-selection',
                  { 'step-2': isStep2 }
                )}>
              <ul>
                { sections.map(section => {
                  return (
                    <li>
                      <a
                        href='#'
                        onClick={() => {
                          setSelectedSection(section);
                          setIsStep2(true);
                      }}>
                        <span>{section.name}</span> <Arrow />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={
                classNames(
                  'section',
                  'product-selection',
                  { 'step-2': isStep2 }
                )}>
              <h3>{ selectedSection.name }</h3>
              <ul>
                { selectedSection && selectedSection.products.map(product => {
                  const sectionName = selectedSection.name.toLowerCase();
                  const productName = product.name.toLowerCase();

                  return (
                    <li>
                      <Link to={`/research/${sectionName}/${productName}`}>
                        <span>{product.name}</span> <Arrow />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </StyledLayout>
        );
      }}
    />
  )
};

export default Research;
