import * as React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { graphql, Link, StaticQuery } from 'gatsby';

import Colors from '../colors';
import logo from '../images/logo-research.svg';
import ArrowLink from '../images/arrow-link.svg';
import AccessShield from '../images/access-shield.svg';

import IResearchSection from '../interfaces/ResearchSection';
import ResearchProduct from '../interfaces/ResearchProduct';

const StyledSection = styled.div`
  width: 90%;
  margin: 65px auto;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-left: 8px solid ${Colors.primary};
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 24px;
  max-width: 1440px;
  min-height: 700px;

  display: flex;
  flex-direction: column;

  .quote {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
  }

  .quote__body {
    font-size: 1.3rem;
    font-style: italic;
  }

  .quote__author {
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

interface BreadcrumbsProps {
  levels: string[]
}

const StyledBreadcrumbs = styled.div`
  display: flex;
  flex-direction: row;

  .breadcrumbs__crumb {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    color: ${Colors.primary};

    &:after {
      content: '/';
      padding-left: 16px;
      padding-right: 16px;
      font-weight: 300;
    }

    &.selected {
      font-weight: 700;
    }
  }

  a {
    color: ${Colors.primary};
    text-decoration: none;
  }
`;

const Breadcrumbs = ({ levels }: BreadcrumbsProps) => {
  return (
    <StyledBreadcrumbs className='breadcrumbs'>
      { levels.map((item, idx) => (
          <span className={classNames('breadcrumbs__crumb', {'selected': idx === levels.length - 1})} key={item}>
            { idx === 0
                ? <Link to='/research'>{ item }</Link>
                : <>{ item }</>
            }
          </span>
        ))
      }
    </StyledBreadcrumbs>
  )
};

const StyledHeader = styled.div`
  margin-top: 40px;
  display: flex;

  .header__logo {
    width: 182px;
    height: 38px;
    position: relative;
    left: -20px;

    img {
      width: 182px;
      height: 38px;
      margin: 0;
    }
  }
`;

const DropdownMenu = styled.ul`
  width: 100%;
  list-style-type: none;
  border-bottom: 2px solid ${Colors.primary};
  display: flex;
  flex-direction: none;
  align-items: flex-end;
  justify-content: space-evenly;

  .item {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    transition: border-color .3s;

    &:hover {
      border-bottom: 2px solid ${Colors.primary};

      .item__dropdown {
        display: block;
      }
    }
  }

  .item__dropdown {
    display: none;
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding-top: 24px;
  }

  .item__dropdown a {
    color: ${Colors.primary};
    text-decoration: none;
  }
`;

interface HeaderProps {
  sections: IResearchSection[];
}

const SectionHeader = ({ sections }: HeaderProps) => {
  return (
    <StyledHeader className='header'>
      <Link to='/research' className='header__logo'>
        <img src={logo} />
      </Link>
      <DropdownMenu>
        { sections.map(section => {
            const sectionName = section.name.toLowerCase();

            return (
              <li className='item'>
                <span className='item__label'>{ section.name }</span>
                <ul className='item__dropdown'>
                  { section.products.map(product => {
                      const productName = product.name.toLowerCase();

                      return (
                        <li>
                          <Link to={`/research/${sectionName}/${productName}`}>{product.name}</Link>
                        </li>
                      );
                    })
                  }
                </ul>
              </li>
            );
          })
        }
      </DropdownMenu>
    </StyledHeader>
  );
};

const StyledBody = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  padding-bottom: 150px;
  justify-content: space-between;

  h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 32px;

    &:after {
      display: block;
      content: '';
      width: 56px;
      border-top: 4px solid white;
      margin-top: 8px;
    }
  }

  .left-content {
    max-width: 392px;

    a {
      color: ${Colors.primary};
      font-size: 1.5rem;
      font-family: 'Open Sans', sans-serif;
      text-decoration: none;
      margin-right: 24px;
    }
  }

  .right-content {
    margin-right: 44px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;

    & .access-shield__image {
      margin-bottom: 48px;
    }

    & .acess-shield_label a {
      text-decoration: none;
      color: ${Colors.primary};
    }

    & .acess-shield_label img {
      margin-bottom: 0;
      margin-left: 16px;
    }
  }

  .section-link {
    display: flex;
    flex-direction: row;
    margin-top: 64px;

    & img {
      margin-bottom: 0;
    }
  }
`;

interface BodyProps {
  product: ResearchProduct;
}

const SectionBody = ({ product }: BodyProps) => {
  return (
    <StyledBody>
      <div className='left-content'>
        <h3>{product.name}</h3>
        <p>{product.description}</p>
        {product.link && <div className='section-link'>
          <a href={product.link} target='_blank'>{product.name} <img src={ ArrowLink } /></a>
        </div>}
      </div>
      <div className='right-content access-shield'>
      { !product.link && (
          <>
            <img src={AccessShield} className='access-shield__image' />
            <div className='acess-shield_label'>
              <a href={`mailto:contact@after-math.es?subject=Access for ${product.name}`}>
                Request <strong>access</strong> <img src={ ArrowLink } />
              </a>
            </div>
          </>
        )
      }
      </div>
    </StyledBody>
  );
};

const query = graphql`
  query ResearchSection($name: String) {
    sectionsYaml(name: { eq: $name }) {
      name
    }

    allSectionsYaml {
      nodes {
        name
        products {
  				name
        }
      }
    }
  }
`;

const ResearchSection = (props: any) => {
  const { product, section } = props;
  const levels = ['Research'];

  if (section) {
    levels.push(section.name);

    if (product) {
      levels.push(product.name);
    }
  }

  return (
    <StaticQuery
      query={query}
      render={data => {
        const sections: IResearchSection[] = data.allSectionsYaml.nodes;

        return (
          <StyledSection>
            <Breadcrumbs levels={levels} />
            <SectionHeader sections={sections} />
            { product
                ? <SectionBody product={product} />
                : <p className='quote'>
                    <q className='quote__body'>
                      There are known knowns. These are things we know that
                      we know. There are known unknowns. That is to say, there
                      are things that we know we don't know. But there are
                      also unknown unknowns: these are things we don't know
                      we don't know
                    </q>
                    <span className='quote__author'>(Rumsfeld D. 2002)</span>
                  </p>
            }
          </StyledSection>
        );
      }}
      />
  );
};

export default ResearchSection;
